// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"

const LogoFilter = ({ fixed, alt }) => {
  return (
    <div>
      <Img
        alt={alt}
        fixed={fixed}
        sx={{
          filter: "grayscale(0%)",
          opacity: 1,
          marginRight: `2rem`,
          ":hover": { filter: `grayscale(100%)`, opacity: `0.8` },
        }}
      />
    </div>
  )
}

export default LogoFilter
