// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import { colors } from "../../utils/theme/colors"
import { fontWeights } from "../../utils/theme/font-weights"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Container from "../layout/container"
import Content from "../../content/plateforme-saas/logo-section.json"
import Span from "../common/span"
import LogoFilter from "../homepage/logo-filter"

const LogosSlider = ({ langKey }) => {
  const { title1, title2 } = Content[langKey]

  const data = useStaticQuery(graphql`
    query LogoSQuery {
      SeelkLogo: file(relativePath: { eq: "logo-separator.png" }) {
        childImageSharp {
          fixed(width: 30, height: 45, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Bic: file(relativePath: { eq: "logos/bic.png" }) {
        childImageSharp {
          fixed(width: 125, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Lacoste: file(relativePath: { eq: "logos/lacoste.png" }) {
        childImageSharp {
          fixed(width: 84, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Playmobil: file(relativePath: { eq: "logos/playmobil.png" }) {
        childImageSharp {
          fixed(width: 101, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Colgate: file(relativePath: { eq: "logos/Colgate.png" }) {
        childImageSharp {
          fixed(width: 192, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      Schleich: file(relativePath: { eq: "logos/schleich.png" }) {
        childImageSharp {
          fixed(width: 162, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      Seb: file(relativePath: { eq: "logos/seb.png" }) {
        childImageSharp {
          fixed(width: 175, height: 40, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      desktop: file(
        relativePath: { eq: "backgrounds/logo_section_background.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobile: file(
        relativePath: { eq: "plateformesLogoSliderBackground.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 376) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const sources = [
    data.mobile.childImageSharp.fluid,
    {
      ...data.desktop.childImageSharp.fluid,
      media: `(min-width: 750px)`,
    },
  ]

  return (
    <BackgroundImage fluid={sources}>
      <Container>
        <div
          sx={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            paddingBottom: [`8rem`, `8rem`, `12rem`, `12rem`],
            marginTop: `5rem`,
          }}
        >
          <div
            sx={{
              width: [`100%`, `100%`, `56%`, `56%`],
            }}
          >
            <h2
              sx={{
                fontSize: [`1.38rem`, `1.38rem`, `2.25rem`, `2.25rem`],
                fontWeight: fontWeights.black,
                color: colors.code.secondary,
                marginBottom: `0px`,
                textAlign: `center`,
                paddingTop: "2rem",
              }}
            >
              {title1}&nbsp;
              <Span text={title2} />
            </h2>
          </div>

          <Img
            alt=""
            fixed={data.SeelkLogo.childImageSharp.fixed}
            sx={{ marginBottom: [`1rem`, `1rem`, `7rem`, `7rem`] }}
          />

          <div
            sx={{
              width: [`100%`, `100%`, `95%`, `100%`],
              display: "flex",
              justifyContent: `space-between`,
              overflowX: ["scroll", null, "hidden", null],
            }}
          >
            <LogoFilter fixed={data.Seb.childImageSharp.fixed} alt="" />
            <LogoFilter fixed={data.Schleich.childImageSharp.fixed} alt="" />
            <LogoFilter fixed={data.Bic.childImageSharp.fixed} alt="" />
            <LogoFilter fixed={data.Playmobil.childImageSharp.fixed} alt="" />
            <LogoFilter fixed={data.Colgate.childImageSharp.fixed} alt="" />
            <LogoFilter fixed={data.Lacoste.childImageSharp.fixed} alt="" />
          </div>
        </div>
      </Container>
    </BackgroundImage>
  )
}

export default LogosSlider
